<template>
  <div class="fixed">
    <video autoplay :muted="muted" loop class="video">
      <source :src="defaultAvatar" type="video/mp4">
    </video>
    <div class="video">
      <div class="header">
          <div class="logo"></div>
          <div class="actions">
            <div class="actions-section">
              <a>加入我们</a>
              <a target="_blank" href="https://work.weixin.qq.com/kfid/kfc4033638dcf0279e6">申请创建</a>
              <a target="_blank" href="https://midao.cloud/pro/">迷岛Pro</a>
              <div v-if="muted" class="sound-on" @click="muted=false"></div>
              <div v-else class="sound-off" @click="muted=true"></div>
            </div>
          </div>
      </div>
      <div class="middle">
          <div class="slogan-section">
            <div class="slogan"></div>
          </div>
          <div>
            <a target="_blank" href="https://apps.apple.com/app/id1638668878">
              <div class="appstore"></div>
            </a>
            <a target="_blank" href="https://midao.cloud/app/MiDao.apk">
              <div class="android"></div>
            </a>
            <div class="image-container">
              <div class="qrcode"></div>
              <div class="overlay">
                <div class="qrcode-big"></div>
              </div>
            </div>
            
          </div>
      </div>
      <div class="footer">
        <div>2022-2023 ©苏州迷岛科技有限公司</div>
        <div><a target="_blank" href="https://beian.miit.gov.cn/">苏ICP备2022026293号-1</a></div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
    name: 'main-section',
    data() {
      return {
        defaultAvatar: require('../assets/p.mp4'),
        muted: true
      }
    }
}
</script>

<style lang="scss" scoped>
.fixed {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.video {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
}

.header {
  width: 100%;
  position: fixed;
  top: 44px;
  padding-left: 60px;
  font-size: 15px;
  width: calc(100% - 164px);
  display: flex;
  justify-content: space-between;
}

.footer {
  width: 100%;
  position: fixed;
  bottom: 24px;
  font-size: 13px;
  text-align: center;

  >div {
    margin-top: 8px;
  }
}

a {
  text-decoration: none;
  color: rgba(255, 255, 255, 0.90);
}

.middle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 100%;
  height: 165px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  >div {
    width: calc(100% - 104px);
    text-align: right;
    display: flex;
    justify-content: end;
    column-gap: 8.5px;

    &.slogan-section {
      width: calc(100% - 100px);
    }
  }
}

.actions-section {
  display: flex;
  column-gap: 24px;
  align-items: center;
}

.image-container {
  position: relative;
}

.overlay {
  position: absolute;
  top: 39px;
  left: -100px;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s;
}

.image-container:hover .overlay {
  opacity: 1;
  visibility: visible;
}
</style>
