<template>
  <main-section />
</template>

<script>
import MainSection from './components/main-section.vue'

export default {
  name: 'App',
  components: {
    MainSection
  }
}
</script>

<style>
html, body {
  height: 100%;
}
#app {
  height: 100%;
  color: rgba(255, 255, 255, 0.90);
  font-family: PingFang SC;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
</style>
